#root header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: transparent;
  background-color: white;
}
#root header > .wrapper {
  padding: 0 20px;
  height: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root header > .wrapper .navbar {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 72px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 70px;
     -moz-column-gap: 70px;
          column-gap: 70px;
  font-weight: 700;
}
#root header > .wrapper .navbar .logo-link > img {
  width: 149px;
}
#root header > .wrapper .navbar .flag-container {
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5.5px;
     -moz-column-gap: 5.5px;
          column-gap: 5.5px;
  margin-left: auto;
}
#root header > .wrapper .navbar .pc-nav-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 700;
  -webkit-column-gap: 48px;
     -moz-column-gap: 48px;
          column-gap: 48px;
}
#root header > .wrapper .navbar .pc-nav-wrapper .nav-item {
  position: relative;
}
#root header > .wrapper .navbar .pc-nav-wrapper .nav-item .pc-nav {
  font-weight: 700;
}
#root header > .wrapper .navbar .pc-nav-wrapper .nav-item .sub-nav-wrapper {
  width: 170px;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root header > .wrapper .navbar .pc-nav-wrapper .nav-item .sub-nav-wrapper a {
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #3E3E3E;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 15px;
  color: white;
}
#root header > .wrapper .navbar .pc-nav-wrapper .nav-item .sub-nav-wrapper a:hover {
  background-color: #2A428D;
  opacity: 1;
}
#root header > .wrapper > .right > button {
  background-color: transparent;
}
#root .black {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  background-color: white;
}
#root .mb-header {
  z-index: 400;
  -webkit-transition: top 0.3s ease-in-out;
  transition: top 0.3s ease-in-out;
  position: fixed;
  top: -100%;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  background-color: #3e3e3e;
}
#root .mb-header .nav-item {
  width: 100%;
}
#root .mb-header .mb-flag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
  font-weight: 700;
  padding: 23px 0;
}
#root .mb-header .mb-sub-menu {
  width: 100%;
  background-color: #2b2b2b;
}
#root .mb-header .mb-menu {
  width: 100%;
  color: white;
  padding: 23px 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-weight: 700;
  font-size: 23px;
}
#root > .open-header {
  top: 62px;
}

@media screen and (max-width: 1440px) {
  #root header > .wrapper > .navbar > .pc-nav-wrapper {
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
  }
}
@media screen and (max-width: 767px) {
  #root header {
    height: 62px;
  }
  #root header .wrapper {
    width: 100%;
    padding: 0 16px;
  }
}