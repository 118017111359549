#root .ks-main {
  padding-top: 72px;
  width: 100%;
}
#root .ks-main .wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .ks-main .wrapper > .fix-button {
  right: 50px;
  bottom: 50px;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
  z-index: 100;
}
#root .ks-main .wrapper > .fix-button .button-content {
  row-gap: 4px;
  width: 99px;
  height: 99px;
  border-radius: 50%;
  background-color: #2a428d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: white;
}
#root .ks-main .wrapper > .fix-button .button-content > img {
  width: 44px;
}
#root .ks-main .wrapper .section {
  width: 1260px;
  height: 877px;
  margin: 0 auto;
  position: relative;
}
#root .ks-main .wrapper .section .back-img {
  position: absolute;
  top: -282px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .ks-main .wrapper .section .back-img > img {
  width: 1920px;
}
#root .ks-main .wrapper .section > .section-title {
  height: 100%;
  position: relative;
  margin-top: 492px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .ks-main .wrapper .section > .section-title > .title-wrapper > .title {
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  color: #2a428d;
  line-height: 140%;
  margin-bottom: 16px;
}
#root .ks-main .wrapper .section > .section-title > .title-wrapper > .text {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
#root .ks-main .wrapper .section2 {
  padding-top: 139px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  height: 710px;
}
#root .ks-main .wrapper .section2 .back-img {
  width: 100%;
}
#root .ks-main .wrapper .section2 .back-img .video-section > video {
  width: 100%;
  top: 0;
  position: absolute;
  height: 710px;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .ks-main .wrapper .section2 .back-img > .back {
  top: 0;
  position: absolute;
  width: 100%;
  height: 710px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
}
#root .ks-main .wrapper .section2 .section2-title {
  position: relative;
  z-index: 10;
  text-align: center;
  color: white;
}
#root .ks-main .wrapper .section2 .section2-title > .title {
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 54px */
  margin-bottom: 16px;
}
#root .ks-main .wrapper .section2 .section2-title > .title > span {
  font-weight: 700;
}
#root .ks-main .wrapper .section2 .section2-title > .text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 28.8px */
}
#root .ks-main .wrapper .section2 .section2-title > .name {
  padding-top: 66px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
#root .ks-main .wrapper .section3 {
  height: 935px;
  padding-top: 146px;
  text-align: center;
}
#root .ks-main .wrapper .section3 .section3-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 32px;
}
#root .ks-main .wrapper .section3 .section3-title .step {
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  width: 74px;
  height: 41px;
  border-radius: 50px;
  background-color: #2a428d;
}
#root .ks-main .wrapper .section3 .section3-title > .title {
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 16px;
  /* 49.4px */
}
#root .ks-main .wrapper .section3 .section3-title > .title > span {
  font-weight: 700;
}
#root .ks-main .wrapper .section3 .section3-title > .text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 1439px) {
  #root .ks-main {
    padding-top: 60px;
  }
  #root .ks-main .wrapper > .fix-button .button-content {
    width: 80px;
    height: 80px;
    font-size: 12px;
  }
  #root .ks-main .wrapper > .fix-button .button-content > img {
    width: 30px;
  }
  #root .ks-main .wrapper .section {
    height: 677px;
  }
  #root .ks-main .wrapper .section .back-img {
    top: -100px;
  }
  #root .ks-main .wrapper .section .back-img > img {
    width: 1023px;
  }
  #root .ks-main .wrapper .section > .section-title {
    margin-top: 402px;
    width: 100%;
    padding: 0 39px;
  }
  #root .ks-main .wrapper .section > .section-title > .title-wrapper > .title {
    font-size: 28px;
  }
  #root .ks-main .wrapper .section > .section-title > .title-wrapper > .text {
    font-size: 13px;
  }
  #root .ks-main .wrapper .section2 {
    padding-top: unset;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 710px;
  }
  #root .ks-main .wrapper .section2 .back-img {
    width: 100%;
  }
  #root .ks-main .wrapper .section2 .back-img .video-section > video {
    width: 100%;
    top: 0;
    position: absolute;
    height: 710px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  #root .ks-main .wrapper .section2 .section2-title {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: white;
  }
  #root .ks-main .wrapper .section2 .section2-title > .title {
    font-size: 24px;
    margin-bottom: 16px;
  }
  #root .ks-main .wrapper .section2 .section2-title > .title > span {
    font-weight: 700;
  }
  #root .ks-main .wrapper .section2 .section2-title > .text {
    font-size: 13px;
  }
  #root .ks-main .wrapper .section2 .section2-title > .name {
    padding-top: 20px;
    font-size: 14px;
  }
  #root .ks-main .wrapper .section3 {
    height: 771px;
    padding-top: 94px;
  }
  #root .ks-main .wrapper .section3 .section3-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 32px;
  }
  #root .ks-main .wrapper .section3 .section3-title .step {
    margin-bottom: 16px;
    font-size: 13px;
    width: 63px;
    height: 32px;
  }
  #root .ks-main .wrapper .section3 .section3-title > .title {
    font-size: 24px;
    font-style: normal;
  }
  #root .ks-main .wrapper .section3 .section3-title > .title > span {
    font-weight: 700;
  }
  #root .ks-main .wrapper .section3 .section3-title > .text {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
}
@media screen and (max-width: 767px) {
  #root .ks-main {
    padding-top: 60px;
  }
  #root .ks-main .wrapper > .fix-button {
    right: 20px;
    bottom: 20px;
  }
  #root .ks-main .wrapper .section {
    width: 100%;
    height: 700px;
  }
  #root .ks-main .wrapper .section .back-img {
    top: -100px;
    left: unset;
    right: -180px;
    -webkit-transform: unset;
            transform: unset;
  }
  #root .ks-main .wrapper .section .back-img > img {
    width: 768px;
  }
  #root .ks-main .wrapper .section > .section-title {
    position: relative;
    z-index: 1;
    margin-top: 322px;
    width: 100%;
    padding: 0 20px;
  }
  #root .ks-main .wrapper .section > .section-title > .title-wrapper > .title {
    font-size: 28px;
  }
  #root .ks-main .wrapper .section > .section-title > .title-wrapper > .text {
    font-size: 13px;
  }
  #root .ks-main .wrapper .section2 .section2-title {
    padding: 0 20px;
  }
  #root .ks-main .wrapper .section3 .section3-title {
    padding: 0 20px;
  }
}