#root .ks-detail {
  padding-top: 72px;
}
#root .ks-detail .ks-detail-main .detail {
  width: 1185px;
  margin: 0 auto;
  padding-bottom: 88px;
  padding-top: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 32px;
     -moz-column-gap: 32px;
          column-gap: 32px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .ks-detail .ks-detail-main .detail > .left > img {
  width: 584px;
  height: 584px;
}
#root .ks-detail .ks-detail-main .detail > .right {
  width: 567px;
}
#root .ks-detail .ks-detail-main .detail > .right > .title-wrapper {
  border-bottom: 2px solid #2a428d;
  margin-bottom: 8px;
  padding-bottom: 25px;
}
#root .ks-detail .ks-detail-main .detail > .right > .title-wrapper > .category {
  color: #2a428d;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 8px;
}
#root .ks-detail .ks-detail-main .detail > .right > .title-wrapper > .name {
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 8px;
}
#root .ks-detail .ks-detail-main .detail > .right > .title-wrapper > .level {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: #2a428d;
}
#root .ks-detail .ks-detail-main .detail > .right > .title-wrapper > .text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
#root .ks-detail .ks-detail-main .detail > .right .feature {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 16px;
}
#root .ks-detail .ks-detail-main .detail > .right .feature > .title {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #2a428d;
  margin-bottom: 8px;
}
#root .ks-detail .ks-detail-main .detail > .right .feature > ul {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
#root .ks-detail .ks-detail-main .detail > .right .chat {
  margin-bottom: 30px;
  border-top: 1px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
}
#root .ks-detail .ks-detail-main .detail > .right .chat .chat-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  border-bottom: 1px solid #dbdbdb;
}
#root .ks-detail .ks-detail-main .detail > .right .chat .chat-wrapper > .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 12px;
  width: 184px;
  background-color: #f5f6f8;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
#root .ks-detail .ks-detail-main .detail > .right .chat .chat-wrapper > .content {
  background-color: white;
}
#root .ks-detail .ks-detail-main .detail > .right .chat .chat-wrapper:last-child {
  border-bottom: unset;
}
#root .ks-detail .ks-detail-main .detail > .right .color {
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 2px solid #2a428d;
  padding-top: 8px;
}
#root .ks-detail .ks-detail-main .detail > .right .color .color-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .ks-detail .ks-detail-main .detail > .right .color .color-text > .title {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #2a428d;
}
#root .ks-detail .ks-detail-main .detail > .right .color .color-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 6px;
     -moz-column-gap: 6px;
          column-gap: 6px;
}
#root .ks-detail .ks-detail-main .detail > .right .color .color-wrapper .color-box {
  border: 1px solid white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
#root .ks-detail .ks-detail-main .detail > .right .contact-button {
  border-radius: 5px;
  width: 100%;
  height: 50px;
  background-color: #2a428d;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
#root .ks-detail .ks-detail-main .detail > .right .contact-button > a {
  color: white;
}
#root .ks-detail .ks-detail-main .order-product {
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
  background-color: #f4f4f4;
}
#root .ks-detail .ks-detail-main .order-product > .wrapper {
  padding-top: 59px;
  width: 1185px;
  margin: 0 auto;
}
#root .ks-detail .ks-detail-main .order-product > .wrapper > .title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
}
#root .ks-detail .ks-detail-main .order-product > .wrapper .product-map {
  text-align: left;
  display: grid;
  grid-template-columns: repeat(4, 284px);
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
  row-gap: 54px;
}
#root .ks-detail .ks-detail-main .order-product > .wrapper .product-map .product {
  text-align: left;
}
#root .ks-detail .ks-detail-main .order-product > .wrapper .product-map .product > img {
  width: 284px;
  height: 284px;
}
#root .ks-detail .ks-detail-main .order-product > .wrapper .product-map .product > .name {
  padding-top: 16px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
#root .ks-detail .ks-detail-main .order-product > .wrapper .product-map .product > .level {
  height: 26px;
  padding-top: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #2a428d;
  margin-bottom: 4px;
}
#root .ks-detail .ks-detail-main .order-product > .wrapper .product-map .product > .text {
  height: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 1439px) {
  #root .ks-detail {
    padding-top: 60px;
  }
  #root .ks-detail .ks-detail-main .detail {
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
    width: 728px;
    padding-bottom: 29px;
    padding-top: 21px;
    -webkit-column-gap: 24px;
       -moz-column-gap: 24px;
            column-gap: 24px;
  }
  #root .ks-detail .ks-detail-main .detail > .left > img {
    width: 384px;
    height: 384px;
  }
  #root .ks-detail .ks-detail-main .detail > .right {
    width: 320px;
  }
  #root .ks-detail .ks-detail-main .detail > .right > .title-wrapper {
    border-bottom: 2px solid #2a428d;
    margin-bottom: 8px;
    padding-bottom: 25px;
  }
  #root .ks-detail .ks-detail-main .detail > .right > .title-wrapper > .category {
    font-size: 14px;
  }
  #root .ks-detail .ks-detail-main .detail > .right > .title-wrapper > .name {
    font-size: 28px;
  }
  #root .ks-detail .ks-detail-main .detail > .right > .title-wrapper > .level {
    font-size: 20px;
  }
  #root .ks-detail .ks-detail-main .detail > .right > .title-wrapper > .text {
    font-size: 16px;
  }
  #root .ks-detail .ks-detail-main .detail > .right .chat .chat-wrapper > .title {
    font-size: 14px;
  }
  #root .ks-detail .ks-detail-main .detail > .right .color {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 8px;
  }
  #root .ks-detail .ks-detail-main .order-product > .wrapper {
    padding-top: 35px;
    width: 728px;
    margin: 0 auto;
  }
  #root .ks-detail .ks-detail-main .order-product > .wrapper .product-map {
    grid-template-columns: repeat(3, 232px);
    -webkit-column-gap: 16px;
       -moz-column-gap: 16px;
            column-gap: 16px;
    row-gap: 24px;
  }
  #root .ks-detail .ks-detail-main .order-product > .wrapper .product-map .product > img {
    width: 232px;
    height: 232px;
  }
}
@media screen and (max-width: 767px) {
  #root .ks-detail {
    padding-top: 60px;
  }
  #root .ks-detail .ks-detail-main .detail {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 360px;
    padding-bottom: 60px;
  }
  #root .ks-detail .ks-detail-main .detail > .left {
    margin-bottom: 32px;
  }
  #root .ks-detail .ks-detail-main .detail > .right {
    width: 320px;
  }
  #root .ks-detail .ks-detail-main .order-product > .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-top: 35px;
    width: 360px;
    margin: 0 auto;
  }
  #root .ks-detail .ks-detail-main .order-product > .wrapper .product-map {
    grid-template-columns: repeat(2, 152px);
    -webkit-column-gap: 16px;
       -moz-column-gap: 16px;
            column-gap: 16px;
    row-gap: 24px;
  }
  #root .ks-detail .ks-detail-main .order-product > .wrapper .product-map .product > img {
    width: 152px;
    height: 152px;
  }
  #root .ks-detail .ks-detail-main .order-product > .wrapper .product-map .product > .name {
    font-size: 16px;
  }
  #root .ks-detail .ks-detail-main .order-product > .wrapper .product-map .product > .level {
    font-size: 14px;
  }
  #root .ks-detail .ks-detail-main .order-product > .wrapper .product-map .product > .text {
    font-size: 12px;
  }
}