#root .question {
  padding-top: 72px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 1403px;
}
#root .question .contact-back {
  background-image: url("../../../../public/assets/contact/contact-back.png");
  background-image: -webkit-image-set(url("../../../../public/assets/contact/contact-back.png") 1x, url("../../../../public/assets/contact/contact-back@2x.png") 2x, url("../../../../public/assets/contact/contact-back@3x.png") 3x);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 336px;
  z-index: 1;
  background-size: cover;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .question .contact-back > .title {
  color: white;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
}
#root .question .contact-back > .text {
  color: white;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
}
#root .question .question-wrapper {
  padding-top: 73px;
  width: 1252px;
  margin: 0 auto;
}
#root .question .question-wrapper .contact-title {
  margin-bottom: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 141px;
  height: 41px;
  background-color: #2a428d;
  border-radius: 50px;
}
#root .question .question-wrapper .input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 30px;
     -moz-column-gap: 30px;
          column-gap: 30px;
  margin-bottom: 35px;
}
#root .question .question-wrapper .input-wrapper > .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 32px;
}
#root .question .question-wrapper .input-wrapper > .left .agreement-btn {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #f5f6f8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 700;
  height: 50px;
  margin-top: 5px;
  padding: 15px;
  width: 434px;
}
#root .question .question-wrapper .input-wrapper > .left .agreement-btn > .left {
  border: 1px solid #949494;
  border-radius: 50%;
  height: 20px;
  margin-right: 5px;
  width: 20px;
}
#root .question .question-wrapper .input-wrapper > .left .agreement-btn .left-check {
  border: initial;
}
#root .question .question-wrapper .input-wrapper > .left .check-btn {
  border: 1px solid;
}
#root .question .question-wrapper .input-wrapper > .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 32px;
}
#root .question .question-wrapper .input-wrapper > .right > .btn {
  background-color: black;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  height: 50px;
  width: 626px;
}
#root .question .question-wrapper .input-wrapper > .right > .is-check-btn {
  background-color: #949494;
  cursor: inherit;
  opacity: 1;
}
#root .question .question-wrapper > .title-wrapper {
  margin-bottom: 61px;
}
#root .question .question-wrapper > .title-wrapper > .title {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
#root .question .question-wrapper > .title-wrapper > .text {
  line-height: 130%;
}
#root .question .etc-text {
  text-align: right;
  margin-top: 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
#root .question .button-send {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 24px;
}
#root .question .button-send .btn {
  border-radius: 5px;
  width: 319px;
  height: 50px;
  background-color: #2a428d;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: white;
}
#root .question .footer-content {
  margin-top: 53px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 22px 30px;
  width: 100%;
  background-color: #eaecf4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .question .footer-content .footer-text {
  line-height: 20px;
}
#root .question .footer-content .footer-text > span {
  cursor: pointer;
  text-decoration: underline;
}
#root .question .footer-content .footer-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 12px;
}
#root .question .footer-content .footer-list .list-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
#root .question .footer-content .footer-list .list-content > .list-img {
  width: 21px;
}
#root .question .footer-content .footer-list .list-content > span {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

@media screen and (max-width: 1439px) {
  #root .question {
    padding-top: 60px;
    height: 1603px;
  }
  #root .question .contact-back {
    height: 240px;
  }
  #root .question .contact-back > .title {
    font-size: 28px;
  }
  #root .question .contact-back > .text {
    font-size: 28px;
  }
  #root .question .question-wrapper {
    padding-top: 73px;
    width: 100%;
    margin: 0 auto;
    padding: 73px 20px 0px 20px;
  }
  #root .question .question-wrapper .contact-title {
    font-size: 14px;
    width: 141px;
    height: 41px;
  }
  #root .question .question-wrapper .input-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .question .question-wrapper .input-wrapper > .left {
    margin-bottom: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .question .question-wrapper .input-wrapper > .left .input-section {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .question .question-wrapper .input-wrapper > .left .input-section > .title {
    font-size: 14px;
    margin-bottom: 8px;
  }
  #root .question .question-wrapper .input-wrapper > .left .agreement-btn {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #f5f6f8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 700;
    height: 50px;
    margin-top: 5px;
    padding: 15px;
    width: 434px;
  }
  #root .question .question-wrapper .input-wrapper > .left .agreement-btn > .left {
    border: 1px solid #949494;
    border-radius: 50%;
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }
  #root .question .question-wrapper .input-wrapper > .left .agreement-btn .left-check {
    border: initial;
  }
  #root .question .question-wrapper .input-wrapper > .left .check-btn {
    border: 1px solid;
  }
  #root .question .question-wrapper .input-wrapper > .right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .question .question-wrapper .input-wrapper > .right .input-section {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .question .question-wrapper .input-wrapper > .right .input-section > .title {
    font-size: 14px;
    margin-bottom: 8px;
  }
  #root .question .question-wrapper .input-wrapper > .right > .btn {
    background-color: black;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    height: 50px;
    width: 626px;
  }
  #root .question .question-wrapper .input-section {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .question .question-wrapper .input-section > .title {
    font-size: 14px;
    margin-bottom: 8px;
  }
  #root .question .question-wrapper > .title-wrapper > .title {
    font-size: 24px;
  }
}
@media screen and (max-width: 767px) {
  #root .question .question-wrapper .footer-content {
    padding: 16px 18px;
  }
  #root .question .question-wrapper .footer-content .footer-list .list-content {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}