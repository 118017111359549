#root .input-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
  position: relative;
}
#root .input-section .title {
  width: 100px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: black;
}
#root .input-section > textarea {
  border-radius: 5px;
  padding-left: 16px;
  padding-top: 11px;
  width: 1140px;
  height: 154px;
  border: 1px solid #dbdbdb;
  color: black;
  background-color: transparent;
}
#root .input-section > input {
  border-radius: 5px;
  padding-left: 16px;
  width: 494px;
  height: 50px;
  color: black;
  border: 1px solid #dbdbdb;
  background-color: transparent;
}

@media screen and (max-width: 1439px) {
  #root .input-section .agreement-btn {
    width: 100%;
  }
  #root .input-section > textarea {
    width: 100%;
  }
  #root .input-section > input {
    width: 100%;
  }
  #root .input-section .right {
    width: 100%;
  }
  #root .input-section .right .is-check-btn {
    width: 100%;
  }
  #root .input-section .left {
    margin-bottom: 20px;
  }
}