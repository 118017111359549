#root footer {
  width: 100%;
  background-color: #242627;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: white;
  font-size: 13px;
  height: 305px;
  padding: 65px 55px;
}
#root footer .footer-content-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root footer .footer-content-section .footer-content-left .copy-right {
  margin-top: 45px;
}
#root footer .footer-content-section .footer-content-left > .logo-wrapper {
  margin-bottom: 17.5px;
  width: 122px;
  height: 40px;
}
#root footer .footer-content-section .footer-content-left .footer-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 56px;
     -moz-column-gap: 56px;
          column-gap: 56px;
}
#root footer .footer-content-section .footer-content-left .footer-content .footer-text {
  line-height: 20px;
}
#root footer .footer-content-section .footer-content-left .footer-content .footer-text > span {
  cursor: pointer;
  text-decoration: underline;
}
#root footer .footer-content-section .footer-content-left .footer-content .footer-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 7px;
}
#root footer .footer-content-section .footer-content-left .footer-content .footer-list .list-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
#root footer .footer-content-section .footer-content-left .footer-content .footer-list .list-content > .list-img {
  width: 16px;
  height: 16px;
}