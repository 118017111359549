#root .ks-about {
  height: 1800px;
  position: relative;
}
#root .ks-about .ks-history .wrapper {
  z-index: 1;
  width: 1440px;
  margin: 0 auto;
  position: relative;
  padding-top: 159px;
  padding-left: 128px;
}
#root .ks-about .ks-history .wrapper > .title {
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
}
#root .ks-about .ks-history .wrapper > .text {
  color: #737373;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 106px;
  min-height: 29px;
}
#root .ks-about .ks-history .wrapper .korea {
  z-index: 100;
  top: 0;
  right: 0;
  position: absolute;
}
#root .ks-about .ks-history .wrapper .about-content .about-history {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 136px;
     -moz-column-gap: 136px;
          column-gap: 136px;
}
#root .ks-about .ks-history .wrapper .about-content .about-history .left > .title {
  color: #2a428d;
  font-size: 36px;
  font-style: normal;
  font-weight: 200;
  line-height: 150%;
  /* 54px */
}
#root .ks-about .ks-history .wrapper .about-content .about-history .left > .title > span {
  font-weight: 700;
}
#root .ks-about .ks-history .wrapper .about-content .about-history .left > .text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
#root .ks-about .ks-history .wrapper .about-content .about-history .left > .img-wrapper {
  width: 255px;
  height: 296.494px;
  margin-top: 45px;
}
#root .ks-about .ks-history .wrapper > .title-wrapper {
  margin-bottom: 68px;
}
#root .ks-about .ks-history .wrapper > .title-wrapper .category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50px;
  margin-bottom: 12px;
  width: 159px;
  height: 41px;
  background-color: #2a428d;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
#root .ks-about .ks-history .wrapper > .title-wrapper > .text {
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 54px */
}
#root .ks-about .ks-history .wrapper > .title-wrapper > .text > span {
  font-weight: 700;
}
#root .ks-about .ks-history .wrapper .certifi-content {
  display: grid;
  grid-template-columns: repeat(4, 275px);
  -webkit-column-gap: 32px;
     -moz-column-gap: 32px;
          column-gap: 32px;
  row-gap: 56px;
}
#root .ks-about .ks-history .wrapper .certifi-content .img-box {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
#root .ks-about .ks-history .wrapper .certifi-content .img-box > img {
  margin-bottom: 14px;
}
#root .ks-about .ks-history .about-img {
  width: 100%;
  position: absolute;
  bottom: 0;
}
#root .certifi {
  height: 2080px;
}

@media screen and (max-width: 1439px) {
  #root .ks-about {
    height: 1600px;
    position: relative;
  }
  #root .ks-about .ks-history .wrapper {
    width: 768px;
    padding-top: 94px;
    padding-left: 20px;
  }
  #root .ks-about .ks-history .wrapper > .title {
    font-size: 28px;
  }
  #root .ks-about .ks-history .wrapper > .text {
    font-size: 20px;
    margin-bottom: 106px;
  }
  #root .ks-about .ks-history .wrapper .korea > img {
    width: 400px;
  }
  #root .ks-about .ks-history .wrapper .about-content .about-history {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-column-gap: 36px;
       -moz-column-gap: 36px;
            column-gap: 36px;
  }
  #root .ks-about .ks-history .wrapper .about-content .about-history .left > .title {
    color: #2a428d;
    font-size: 28px;
  }
  #root .ks-about .ks-history .wrapper .about-content .about-history .left > .text {
    font-size: 13px;
  }
  #root .ks-about .ks-history .wrapper > .title-wrapper {
    margin-bottom: 68px;
  }
  #root .ks-about .ks-history .wrapper > .title-wrapper > .text {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 54px */
  }
  #root .ks-about .ks-history .wrapper > .title-wrapper > .text > span {
    font-weight: 700;
  }
  #root .ks-about .ks-history .wrapper .certifi-content {
    grid-template-columns: repeat(3, 232px);
    -webkit-column-gap: 16px;
       -moz-column-gap: 16px;
            column-gap: 16px;
    row-gap: 32px;
  }
  #root .ks-about .ks-history .wrapper .certifi-content .img-box {
    font-size: 13px;
  }
  #root .ks-about .ks-history .about-img {
    width: 100%;
    position: absolute;
    height: 550px;
    bottom: 0;
  }
  #root .ks-about .ks-history .about-img > img {
    height: 550px;
  }
  #root .certifi {
    height: 2080px;
  }
}
@media screen and (max-width: 767px) {
  #root .ks-about {
    height: 1550px;
    position: relative;
  }
  #root .ks-about .ks-history .wrapper {
    width: 360px;
    padding-top: 94px;
    padding-left: 20px;
  }
  #root .ks-about .ks-history .wrapper > .title {
    font-size: 28px;
  }
  #root .ks-about .ks-history .wrapper > .text {
    font-size: 20px;
    margin-bottom: 106px;
  }
  #root .ks-about .ks-history .wrapper .korea {
    top: 60px;
    position: absolute;
    right: unset;
  }
  #root .ks-about .ks-history .wrapper .korea > img {
    width: 320px;
  }
  #root .ks-about .ks-history .wrapper .about-content .about-history {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .ks-about .ks-history .wrapper .about-content .about-history .left > .title {
    color: #2a428d;
    font-size: 28px;
  }
  #root .ks-about .ks-history .wrapper .about-content .about-history .left > .text {
    font-size: 13px;
  }
  #root .ks-about .ks-history .wrapper .about-content .about-history .left > .img-wrapper {
    margin: 0 auto;
  }
  #root .ks-about .ks-history .wrapper .about-content .about-history .right {
    padding-top: 43px;
    width: 320px;
  }
  #root .ks-about .ks-history .wrapper > .title-wrapper {
    margin-bottom: 32px;
  }
  #root .ks-about .ks-history .wrapper > .title-wrapper .category {
    width: 133px;
    height: 34px;
    color: white;
    font-size: 12px;
  }
  #root .ks-about .ks-history .wrapper > .title-wrapper > .text {
    font-size: 24px;
  }
  #root .ks-about .ks-history .wrapper .certifi-content {
    display: grid;
    grid-template-columns: repeat(2, 152px);
    -webkit-column-gap: 16px;
       -moz-column-gap: 16px;
            column-gap: 16px;
    row-gap: 32px;
  }
  #root .ks-about .ks-history .wrapper .certifi-content .img-box {
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
  }
  #root .ks-about .ks-history .wrapper .certifi-content .img-box > img {
    margin-bottom: 14px;
  }
  #root .ks-about .ks-history .about-img {
    width: 100%;
    position: absolute;
    height: 280px;
    bottom: 0;
  }
  #root .ks-about .ks-history .about-img > img {
    height: 280px;
  }
  #root .certifi {
    height: 2100px;
  }
}