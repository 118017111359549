.ks-silde {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.ks-silde .preview-container {
  position: relative;
  width: 890px;
  overflow: hidden;
}
.ks-silde .preview-container > .arrow-left {
  z-index: 10;
  top: 35%;
  width: 55px;
  height: 55px;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  left: 0;
}
.ks-silde .preview-container > .arrow-right {
  z-index: 10;
  top: 35%;
  right: 0;
  width: 55px;
  height: 55px;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
}
.ks-silde .preview-container .container-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.ks-silde .preview-container .container-wrapper .preview-image {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.ks-silde .preview-container .container-wrapper .preview-image img {
  width: 441px;
}
.ks-silde .preview-container .container-wrapper .preview-image > .name {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-top: 4px;
}
.ks-silde .preview-container::-webkit-scrollbar {
  display: none;
}
.ks-silde .pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 66px;
}
.ks-silde .pagination .page-button {
  border: 1px solid #ccc;
  background-color: #bfc6dd;
  width: 57px;
  height: 6px;
  margin: 0 2px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 18px;
}
.ks-silde .pagination .page-button.active {
  background-color: #2a428d;
}
.ks-silde .pagination .page-button.circle {
  border-radius: 50%;
}
.ks-silde .pagination .page-button.square {
  border-radius: 0;
}
.ks-silde .popup {
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.9);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 100;
}
.ks-silde .popup .popup-wrapper {
  margin-bottom: 8px;
  width: 739px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.ks-silde .popup .popup-wrapper > .popup-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: white;
}
.ks-silde .popup .popup-wrapper > img {
  width: 32px;
  height: 32px;
}
.ks-silde .popup .popup-content {
  cursor: pointer;
}
.ks-silde .popup .popup-content > img {
  width: 739px;
  height: 381px;
  margin-bottom: 36px;
}
.ks-silde .popup .other-images {
  cursor: pointer;
  width: 739px;
  height: 125px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
.ks-silde .popup .button-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 800px;
  position: absolute;
}

@media screen and (max-width: 1439px) {
  #root .ks-silde .preview-container {
    position: relative;
    width: 720px;
    overflow: hidden;
  }
  #root .ks-silde .preview-container .container-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  #root .ks-silde .preview-container .container-wrapper .preview-image {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
  }
  #root .ks-silde .preview-container .container-wrapper .preview-image img {
    width: 360px;
  }
  #root .ks-silde .popup .popup-wrapper {
    margin-bottom: 8px;
    width: 680px;
  }
  #root .ks-silde .popup .popup-wrapper > .popup-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: white;
  }
  #root .ks-silde .popup .popup-wrapper > img {
    width: 32px;
    height: 32px;
  }
  #root .ks-silde .popup .popup-content {
    cursor: pointer;
  }
  #root .ks-silde .popup .popup-content > img {
    width: 680px;
    height: 381px;
    margin-bottom: 36px;
  }
  #root .ks-silde .popup .other-images {
    cursor: pointer;
    width: 739px;
  }
  #root .ks-silde .popup .button-wrapper {
    gap: 690px;
  }
}
@media screen and (max-width: 767px) {
  #root .ks-silde .preview-container {
    width: 360px;
  }
  #root .ks-silde .preview-container > .arrow-left {
    width: 50px;
    height: 50px;
  }
  #root .ks-silde .preview-container > .arrow-right {
    width: 50px;
    height: 50px;
  }
  #root .ks-silde .popup {
    display: none;
  }
}