#root .ks-product {
  position: relative;
}
#root .ks-product .ks-product-main .wrapper {
  position: relative;
  width: 1184px;
  margin: 0 auto;
  padding-top: 159px;
}
#root .ks-product .ks-product-main .wrapper > .title {
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
}
#root .ks-product .ks-product-main .wrapper > .text {
  color: #737373;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 126px;
  min-height: 29px;
}
#root .ks-product .ks-product-main .wrapper .korea {
  z-index: 101;
  top: 0;
  right: 0;
  position: absolute;
}
#root .ks-product .ks-product-main .wrapper .search-filter {
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .ks-product .ks-product-main .wrapper .search-filter > img {
  right: 16px;
  top: 8px;
  position: absolute;
  width: 32px;
}
#root .ks-product .ks-product-main .wrapper .search-filter > input {
  padding-left: 16px;
  width: 319px;
  height: 50px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}
#root .ks-product .ks-product-main .wrapper .drop-down-section {
  width: 320px;
  height: 50px;
  position: relative;
  z-index: 10;
}
#root .ks-product .ks-product-main .wrapper .drop-down-section .drop-down-wrapper {
  width: 100%;
  max-height: 50px;
  position: absolute;
  overflow: hidden;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  display: grid;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  background-color: #dbdbdb;
  row-gap: 1px;
}
#root .ks-product .ks-product-main .wrapper .drop-down-section .drop-down-wrapper .drop-down-card {
  background-color: white;
  width: 100%;
  height: 50px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 12px;
  color: #696969;
}
#root .ks-product .ks-product-main .wrapper .drop-down-section .drop-down-open {
  max-height: 610px;
}
#root .ks-product .ks-product-main .wrapper .product-content .product-category {
  padding: 16px 0px 24px 0;
  border-top: 3px solid #2a428d;
}
#root .ks-product .ks-product-main .wrapper .product-content .product-category .category {
  margin-bottom: 24px;
  color: #2a428d;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
#root .ks-product .ks-product-main .wrapper .product-content .product-category .product-none {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  height: 150px;
  background-color: #dbdbdb;
}
#root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map {
  text-align: left;
  margin-bottom: 150px;
  display: grid;
  grid-template-columns: repeat(4, 284px);
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
  row-gap: 54px;
}
#root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map .product {
  text-align: left;
}
#root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map .product > img {
  width: 284px;
  height: 284px;
}
#root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map .product > .name {
  padding-top: 16px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
#root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map .product > .level {
  height: 26px;
  padding-top: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #2a428d;
  margin-bottom: 4px;
}
#root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map .product > .text {
  height: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
#root .ks-product .ks-product-main .wrapper .product-content .non-taget {
  display: none;
  border-top: unset;
}

@media screen and (max-width: 1439px) {
  #root .ks-product .ks-product-main .wrapper {
    width: 728px;
    margin: 0 auto;
    padding-top: 95px;
  }
  #root .ks-product .ks-product-main .wrapper > .title {
    font-size: 28px;
  }
  #root .ks-product .ks-product-main .wrapper > .text {
    font-size: 20px;
  }
  #root .ks-product .ks-product-main .wrapper .korea {
    z-index: 100;
    top: 0;
    right: 0;
    position: absolute;
  }
  #root .ks-product .ks-product-main .wrapper .korea > img {
    width: 450px;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .product-category {
    padding: 16px 0px 24px 0;
    border-top: 3px solid #2a428d;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .product-category .category {
    font-size: 18px;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map {
    text-align: left;
    margin-bottom: 150px;
    display: grid;
    grid-template-columns: repeat(3, 232px);
    -webkit-column-gap: 16px;
       -moz-column-gap: 16px;
            column-gap: 16px;
    row-gap: 54px;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map .product {
    text-align: left;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map .product > img {
    width: 232px;
    height: 232px;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map .product > .name {
    padding-top: 16px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map .product > .level {
    height: 26px;
    padding-top: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    color: #2a428d;
    margin-bottom: 4px;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map .product > .text {
    height: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .non-taget {
    display: none;
    border-top: unset;
  }
}
@media screen and (max-width: 767px) {
  #root .ks-product .ks-product-main .wrapper {
    width: 320px;
    margin: 0 auto;
    padding-top: 95px;
  }
  #root .ks-product .ks-product-main .wrapper .search-filter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 13px;
  }
  #root .ks-product .ks-product-main .wrapper .search-filter > img {
    right: 16px;
    top: 70px;
    position: absolute;
    width: 32px;
  }
  #root .ks-product .ks-product-main .wrapper > .title {
    font-size: 28px;
  }
  #root .ks-product .ks-product-main .wrapper > .text {
    font-size: 20px;
  }
  #root .ks-product .ks-product-main .wrapper .korea {
    top: 60px;
    position: absolute;
    right: unset;
  }
  #root .ks-product .ks-product-main .wrapper .korea > img {
    width: 320px;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .product-category {
    padding: 16px 0px 24px 0;
    border-top: 3px solid #2a428d;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .product-category .category {
    font-size: 18px;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map {
    text-align: left;
    margin-bottom: 150px;
    display: grid;
    grid-template-columns: repeat(2, 152px);
    -webkit-column-gap: 16px;
       -moz-column-gap: 16px;
            column-gap: 16px;
    row-gap: 16px;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map .product > img {
    width: 152px;
    height: 152px;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map .product > .name {
    padding-top: 16px;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map .product > .level {
    font-size: 14px;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .product-category .product-map .product > .text {
    font-size: 12px;
  }
  #root .ks-product .ks-product-main .wrapper .product-content .non-taget {
    display: none;
    border-top: unset;
  }
}