* {
  --grey600: #242627;
  --grey500: #54585a;
  --grey400: #949494;
  --grey300: #c3c3c3;
  --grey200: #dbdbdb;
  --grey100: #f5f6f8;
  --red600: #de3703;
  --red100: #fcebe6;
  --white: #ffffff;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
* [placeholder]:empty:focus::before {
  content: "";
}
* [contenteditable=true] {
  outline: unset;
}
* [contenteditable=true]:empty:before {
  content: attr(placeholder);
  display: block;
  /* For Firefox */
  color: #bfbfbf;
  font-size: 17px;
  font-family: "pretendard", sans-serif;
}

@font-face {
  font-family: "gmarket";
  font-weight: 700;
  font-display: swap;
  src: local("GemunuLibre Bold"), url("./woff/GmarketSansTTFBold.ttf") format("woff");
}
@font-face {
  font-family: "scdream7";
  font-weight: 700;
  font-display: swap;
  src: local("GemunuLibre Bold"), url("./woff/SCDream7.otf") format("woff");
}
@font-face {
  font-family: "scdream3";
  font-weight: 700;
  font-display: swap;
  src: local("GemunuLibre Bold"), url("./woff/SCDream3.otf") format("woff");
}
.gmarket {
  font-family: "gmarket", sans-serif !important;
  font-weight: 700;
  font-style: normal;
}

.scdream3 {
  font-family: "scdream3", sans-serif !important;
  font-weight: 200;
  font-style: normal;
}

.scdream7 {
  font-family: "scdream7", sans-serif !important;
  font-weight: 700;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "pretendard", sans-serif;
  color: black;
  -webkit-overflow-scrolling: touch;
}

main {
  min-height: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

ul,
ol,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: black;
  text-decoration: none;
}

img {
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

input,
p,
textarea {
  border: 0;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-appearance: none;
  resize: none;
  font-family: "pretendard", sans-serif;
}
input::-webkit-input-placeholder, p::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input::-moz-placeholder, p::-moz-placeholder, textarea::-moz-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input:-ms-input-placeholder, p:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input::-ms-input-placeholder, p::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input::placeholder,
p::placeholder,
textarea::placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input:focus,
p:focus,
textarea:focus {
  outline: none;
}

button,
a,
label {
  padding: unset;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  background-color: unset;
}
button:focus,
a:focus,
label:focus {
  outline: none;
}
button:hover,
a:hover,
label:hover {
  opacity: 0.7;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

#root {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-x: hidden;
}
#root font[size="1"] {
  font-size: 13px;
}
#root font[size="2"] {
  font-size: 15px;
}
#root font[size="3"] {
  font-size: 17px;
}
#root font[size="4"] {
  font-size: 19px;
}
#root font[size="5"] {
  font-size: 21px;
}
#root font[size="6"] {
  font-size: 23px;
}
#root font[size="7"] {
  font-size: 25px;
}

#root::-webkit-scrollbar {
  display: none;
}